import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import splash from "../images/ffl/splash.jpg"
import route from "../images/ffl/route.png"
import sort from "../images/ffl/sort.png"
import filter from "../images/ffl/filter.png"
import navi from "../images/ffl/navi.png"
import login from "../images/ffl/login.png"

import target from "../images/ffl/target.jpeg"

import sketch1 from "../images/ffl/sketch1.png"
import sketch2 from "../images/ffl/sketch2.png"
import sketch3 from "../images/ffl/sketch3.jpg"

import wireframe1 from "../images/ffl/wireframe1.jpg"
import wireframe2 from "../images/ffl/wireframe2.jpg"
import wireframe3 from "../images/ffl/wireframe3.jpg"

const SecondPage = ({ data }) => (
  <Layout>
    <Seo title="Dan Flavin" />
    <div className="light-grey">
      <div className="content grid space">
        <div className="col-1">
          <h2>
            PWA for
            <br />
            Fit for Life
          </h2>
          <p className="teaser-text">
            For the magazine{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.fitforlife.ch/"
            >
              Fit for Life
            </a>{" "}
            a{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://en.wikipedia.org/wiki/Progressive_web_application"
            >
              PWA
            </a>{" "}
            was designed. The app is meant to be a digital companion to the
            magazine, which is all about outdoor sports like running or
            mountain-biking. The app assits both with trip preparation as well
            as during the activity, for example by providing turn-by-turn
            navigations.
          </p>
        </div>

        <StaticImage
          src="teaser/fitforlife.png"
          width={725}
          className="col-2"
          layout="fixed"
          alt="Fit for Life Mockup"
        />
      </div>
    </div>
    <div className="content space ffl-grid">
      <img src={splash} alt="Splash screen" />
      <img className="rounded shadow" src={route} alt="Route screen" />
      <img className="rounded shadow" src={sort} alt="Sorting screen" />
      <img className="rounded shadow" src={filter} alt="Filter/search screen" />
      <img className="rounded shadow" src={navi} alt="Navigation screen" />{" "}
      <img className="rounded shadow" src={login} alt="Login screen" />
      <StaticImage
        src="teaser/home.png"
        width={330}
        className="moddle"
        layout="fixed"
        alt="Home screen"
      />
      <StaticImage
        src="ffl/corviglia.png"
        width={330}
        className="top-right"
        layout="fixed"
        alt="Tour Screen"
      />
    </div>
    <div className="content">
      <div className="grid space-below">
        <div className="col-1">
          <h3>
            Defining the
            <br />
            Target Group
          </h3>
          <p>
            Before jumping straight into the design, a good understanding of the
            audience is important. In this project, I used good old-fashioned
            sticky notes to brainstorm the needs of users and find possible ways
            to cater for them.
          </p>
        </div>
        <img className="col-2" src={target} alt="Sticky notes" />
      </div>
      <div className="grid space-below">
        <div className="col-1">
          <h3>
            Sketches and
            <br />
            Wireframes
          </h3>
          <p>
            After trying some ideas on paper, the best solutions were
            digitalized into wireframes.
          </p>
        </div>
        <div className="col-2 sketches">
          <img src={sketch1} alt="Sketch" />
          <img src={sketch2} alt="Sketch" />
          <img src={sketch3} alt="Sketch" />
        </div>
        <div className="col-start-2 col-2 sketches">
          <img className="shadow" src={wireframe1} alt="Wireframe" />
          <img className="shadow" src={wireframe2} alt="Wireframe" />
          <img className="shadow" src={wireframe3} alt="Wireframe" />
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
